.Instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;
  &__banner {
    display: flex;
    gap: 48px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 48px;
    font-size: 48px;
    font-weight: 600;
    @media screen and (max-width: 860px) {
      gap: 24px;
      flex-direction: column;
      font-size: 16px;
    }
    span {
      color: #6854ff;
    }
    img {
      max-width: 400px;
      height: auto;
      width: 100%;
    }
  }
  &__apps {
    margin-top: 24px;
    margin-bottom: 24px;
    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &__buttons {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      align-items: center;
      justify-content: center;
      & > div {
        width: 200px;
        img {
          width: 100%;
          height: auto;
        }
      }
      @media screen and (max-width: 860px) {
        flex-direction: column;
      }
    }
  }
  &__cta {
    width: 100%;
    text-align: center;
  }
  &__steps {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    color: white;
    font-size: 14px;
  }
  &__step {
    border-radius: 18px;
    padding: 32px;
    max-width: 310px;
    &__text {
      margin-bottom: 16px;
    }
    li {
      margin-top: 8px;
    }
    &__number {
      width: 100%;
      text-align: right;
      font-size: 12px;
      margin-bottom: 8px;
    }
    img {
      margin-left: 24px;
      margin-bottom: 40px;
    }
    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &--1 {
      background: #6854ff;
    }
    &--2 {
      background: #ff8d54;
    }
    &--3 {
      background: #53c28d;
    }
    &--4 {
      background: #ffcd00;
      img {
        margin-left: -20px;
      }
    }
    &--5 {
      background: white;
      color: #191919;
      .Instructions__step__title {
        color: #6854ff;
      }
    }
  }
}
