.PaymentMethod {
  &__discount {
    background: rgba(83, 194, 141, 0.5);
    font-weight: 500;
    font-size: 18px;
    padding: 12px;
    width: auto;
    border-radius: 12px;
    margin-bottom: 24px;
  }

  &__card {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid;
    padding: 12px;
    border-radius: 12px;
  }

  &__error {
    color: red;
  }

  &__read-more {
    text-align: right;

    a {
      font-size: 14px;
      font-weight: 300;
      color: #6854ff;
    }

    margin-bottom: 24px;
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 48px;
  }

  &__plans {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 16px;

    @media screen and (max-width: 860px) {
      flex-direction: column;
    }
  }

  &__plan {
    flex: 1;
    background: #F1F0FF;
    border-radius: 32px;
    overflow: hidden;
    border: 6px solid rgba(104, 84, 255, .2);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &--active {
      border: 6px solid #6854FF;
    }

    &__image {
      background: linear-gradient(180deg, #FF8D54 0%, #EED2C4 100%);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

    }

    &--pro {
      &__image {
        background: linear-gradient(180deg, #35CCAD 0%, #55C9B1 100%);
      }
    }

    &__content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 24px;
    }

    &__price {
      margin-top: auto;

      .amount {
        display: block;
        font-size: 34px;
        font-weight: 500;

        &__vat {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .terms {
        font-size: 20px;
        font-weight: 400;
        color: #A1A1A1;
      }
    }
  }

  &__image {
    img {
      width: 100%;
      max-width: 379px;
    }
  }
}