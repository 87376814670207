.QRScan {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__text {
    margin-bottom: 32px;
  }
  &__status-image {
    margin-bottom: 16px;
  }
  &__actions {
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 600px;
    button {
      width: 100%;
      height: 50px;
    }
  }
  .title {
    margin-bottom: 24px;
  }
  &__image {
    padding: 40px;
    text-align: center;
    max-width: 360px;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__qr-wrapper {
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
  }
  &__validated {
    font-weight: 600;
    margin-bottom: 24px;
  }
  &__rejected {
    color: #ff6854;
    margin-bottom: 24px;
    font-weight: 600;
  }
  &__req {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__item {
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__apps {
    margin-top: 48px;
    margin-bottom: 24px;
    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
    }
    &__buttons {
      display: flex;
      gap: 16px;
      margin-top: 16px;
      align-items: center;
      justify-content: center;
      & > div {
        width: 200px;
        img {
          width: 100%;
          height: auto;
        }
      }
      @media screen and (max-width: 860px) {
        flex-direction: column;
      }
    }
  }
}
