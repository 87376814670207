.CreateAccount {
  &__title {
    color: #191919;
    margin-bottom: 40px;
    margin-top: 18px;
    font-weight: 400;
    font-size: 24px;
  }

  &__box {
    background: white;
    max-width: 1280px;
    width: 100%;
    border-radius: 32px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  &__main {
    padding: 48px;

    @media screen and (max-width: 1000px) {
      flex: 1;
      padding: 24px;
    }
  }

  &__steps {
    color: #6854ff;
    font-size: 16px;
    margin-bottom: 12px;
  }

  &__banner {
    @media screen and (max-width: 1000px) {
      display: none;
    }

    img {
      height: 100%;
    }
  }

  &__step {
    &__title {
      font-size: 40px;
      font-weight: 400;

      span {
        color: #6854ff;
      }

      @media screen and (max-width: 860px) {
        font-size: 28px;
        line-height: 120%;
      }
    }

    &__subtitle {
      margin: 24px 0;
      font-size: 16px;
      opacity: 0.6;
    }

    &__discount {
      margin-top: 12px;

      &__title {
        margin-bottom: 12px;
      }
    }

    &__submit {
      width: 100%;
      height: 48px;

      &--restaurants {
        margin-top: 24px !important;
      }
    }
  }

  .Checkbox {
    margin-bottom: 16px;

    .MuiFormControlLabel-label {
      font-size: 14px;
      line-height: 100%;

      a {
        color: #6854ff;

        &:visited {
          color: #6854ff;
        }
      }
    }
  }
}