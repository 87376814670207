.PaymentInfoModal {
  color: #191919;

  &__steps {
    color: #6854ff;
    font-size: 16px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 24px;

    span {
      color: #6854ff;
    }

    @media screen and (max-width: 860px) {
      font-size: 28px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    opacity: 0.6;
  }

  &__section__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}