.CampaignForm {
  &__label {
    color: #626262;
    margin-bottom: 12px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__inputs {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 32px;
    & > div {
      flex: 1;
    }
    .MuiTextField-root {
      min-width: 280px;
      margin-bottom: 0 !important;
      .MuiInputBase-root {
        background: #f1f0ff;
        border-radius: 10px;
        input,
        textarea {
          &::placeholder {
            color: #898989 !important;
          }
        }
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
  &__actions {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}
