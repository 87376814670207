.RestaurantFile {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 26px;
    h1 {
      margin-bottom: 16px;
    }
  }
  &__campaign-box {
    background: white;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 32px;
    font-size: 14px;
    &__alert {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  &__cards {
    flex-wrap: wrap;
    display: flex;
    gap: 48px;
    margin-bottom: 24px;
  }
  &__card {
    max-width: 394px;
    width: 100%;
    &__label {
      font-size: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  &__access {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__item {
      &__label {
        color: #cccccc;
        font-size: 12px;
        margin-bottom: 8px;
      }
      &__value {
        font-size: 18px;
        &--address {
          font-size: 14px;
        }
        a {
          font-size: 14px;
          color: #6854ff;
        }
      }
    }
  }
  &__qr {
    &__download {
      text-align: center;
      margin-top: 24px;
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
    &__upper {
      text-align: center;
      padding: 20px 32px 8px;
      font-weight: 300;
      &__title {
        color: #6854ff;
        font-size: 45px;
        margin-bottom: 16px;
        font-weight: 500;
        line-height: 89%;
      }
      &__subtitle {
        color: #191919;
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 400;
      }
    }
    &__bottom {
      background-color: #6854ff;
      padding-top: 28px;
      padding-bottom: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &__name {
        position: absolute;
        color: white;
        bottom: 1px;
        font-size: 8px;
        opacity: 0.8;
        width: 100%;
        text-align: end;
        padding-right: 20px;
        box-sizing: border-box;
        display: block;
      }
      &__logo {
        position: absolute;
        bottom: 12px;
        width: 100%;
        text-align: center;
        width: 62px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &__form {
    &__label {
      color: #cccccc;
      margin-bottom: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__inputs {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      & > div {
        flex: 1;
      }
      .MuiTextField-root {
        min-width: 280px;
        margin-bottom: 0 !important;
        .MuiInputBase-root {
          background: #f1f0ff;
          border-radius: 10px;
          input,
          textarea {
            &::placeholder {
              color: #cccccc;
            }
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    &__actions {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
  }
}
