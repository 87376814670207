.AdminLayout {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
  &__header {
    grid-area: header;
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    &--hidden {
      transform: translateY(-100%);
    }
  }
  &__main {
    grid-area: main;
    padding: 24px;
    max-width: 1536px;
    width: 100%;
    justify-self: center;
    box-sizing: border-box;
    &--fullwidth {
      max-width: 100vw;
      padding: 0;
    }
  }
  &__footer {
    grid-area: footer;
  }
}
