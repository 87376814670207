.HowItWorks {
  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 56px;
    color: #6854ff;
  }
  &__cards {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      gap: 14px;
      align-items: center;
    }
  }
  &__card {
    flex: 1 1 calc(33.333% - 16px);
    @media screen and (max-width: 780px) {
      flex: 1;
    }
    max-width: 420px;
    border-radius: 32px;
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 16px;
      padding: 48px 24px 0px;
    }
    &__text {
      padding: 0 24px;
    }
    &__image {
      text-align: center;
      img {
        display: block;
        width: 100%;
      }
    }
    &--1 {
      background-color: #6854ff;
    }
    &--2 {
      background-color: #ff8d54;
      .HowItWorks__card__image {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
      img {
        margin-left: 30px;
        max-width: 300px;
      }
    }
    &--3 {
      background-color: #ff5454;
    }
    &--4 {
      background-color: #53c28d;
    }
    &--5 {
      background-color: #fac900;
    }
    &--6 {
      background-color: #548dff;
    }
  }
}
