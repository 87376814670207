.RestaurantInfo {
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 24px;
    &--second {
      margin-top: 40px;
    }
  }
  &__form {
    &__label {
      color: #626262;
      margin-bottom: 12px;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
      max-height: 16px;
    }
    &__inputs {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      & > div {
        flex: 1;
      }
      .MuiAutocomplete-root {
        width: 100%;
      }
      .MuiTextField-root,
      .MuiAutocomplete-root {
        min-width: 280px;
        margin-bottom: 0 !important;
        .MuiInputBase-root {
          background: #f1f0ff;
          border-radius: 10px;
          input,
          textarea {
            &::placeholder {
              color: #898989;
              opacity: 1;
            }
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    &__actions {
      margin-top: 32px;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
    }
  }
}
