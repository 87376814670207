.RestaurantList {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 26px;
    h1 {
      margin-bottom: 16px;
    }
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    &__title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 16px;
    }
    &__subtitle {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
  .MuiDataGrid-root {
    border: none;
    border-radius: 18px;
    overflow: hidden;
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding: 0 24px !important;
  }
  .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
    color: #cccccc;
    font-size: 12px;
    font-weight: 400 !important;
    font-family: "DM Sans";
  }
  .MuiDataGrid-cell {
    color: #191919;
    font-size: 16px;
    font-weight: 400 !important;
    font-family: "DM Sans";
  }
}
