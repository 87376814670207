.RestaurantDetail {
  &__container {
    width: 100%;
    background: white;
    border-radius: 20px;
  }
  &__inactive {
    background: #ff5454;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 32px;
    font-size: 14px;
    color: white;
    &__title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  &__tab {
    padding: 24px;
  }
}
