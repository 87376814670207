.Accordion {
  &__item {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 24px;
    font-weight: 300;
  }
  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    cursor: pointer;
  }
  &__answer {
    background: #f1f0ff;
    padding: 0 24px;
  }
}
