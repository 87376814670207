.RestaurantDisplays {
  &__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &__text {
    margin-bottom: 4px;
  }
  &__tip {
    margin-top: 16px;
    font-size: 12px;
  }
  &__list {
    margin-top: 48px;
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
  }
  &__qr {
    max-width: 394px;
    border: 1px solid rgba(104, 84, 255, 0.4);
    box-shadow: 0px 2px 2px rgba(104, 84, 255, 0.1);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__sticker {
      border-radius: 18px;
      overflow: hidden;
    }
    &__download {
      text-align: center;
      margin-top: 24px;
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
    &__upper {
      text-align: center;
      padding: 20px 18px 8px;
      font-weight: 300;
      &__title {
        color: #6854ff;
        font-size: 45px;
        margin-bottom: 16px;
        font-weight: 500;
        line-height: 89%;
      }
      &__subtitle {
        color: #191919;
        font-size: 20px;
        margin-bottom: 12px;
        font-weight: 400;
      }
    }
    &__bottom {
      background-color: #6854ff;
      padding-top: 28px;
      padding-bottom: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &__name {
        position: absolute;
        color: white;
        bottom: 1px;
        font-size: 8px;
        opacity: 0.8;
        width: 100%;
        text-align: end;
        padding-right: 20px;
        box-sizing: border-box;
        display: block;
      }
      &__logo {
        position: absolute;
        bottom: 12px;
        width: 100%;
        text-align: center;
        width: 62px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
