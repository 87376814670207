.Home {
  color: white;

  span {
    color: #fac900;
  }

  &__banner {
    background-image: url("./images/banner.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 32px;
    width: 100%;
    text-align: center;
    height: 507px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 24px;

    &__title {
      font-weight: 500;
      font-size: 48px;
      display: flex;
      gap: 24px;
      align-items: center;

      @media screen and (max-width: 780px) {
        flex-direction: column;
        gap: 14px;
      }

      &__logo {
        background: white;
        padding: 28px;
        border-radius: 24px;
        display: flex;

        @media screen and (max-width: 780px) {
          padding: 20px;

          img {
            width: 222px;
          }
        }
      }
    }

    &__subtitle {
      font-size: 24px;
      font-weight: 500;
    }

    &__button {
      button {
        background-color: #fac900;
        color: #191919;
        height: 48px;
        width: 244px;
        text-transform: none;

        &:hover {
          background-color: #fac900;
        }
      }
    }

    &__demo {
      a {
        color: white;
      }
    }
  }

  &__section1 {
    font-weight: 500;
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    &>div {
      flex: 1;

      &:first-child {
        display: flex;
        gap: 24px;
        flex-direction: column;
      }
    }

    &__card {
      border-radius: 32px;
      padding: 64px 48px;
      flex: 1;

      @media screen and (max-width: 780px) {
        padding: 60px 24px;
      }
    }

    &__card1 {
      background-color: white;
      color: black;
      font-size: 40px;

      @media screen and (max-width: 780px) {
        font-size: 34px;
      }

      span {
        color: #6854ff;
      }
    }

    &__card2 {
      background-color: #ff5454;
      font-size: 34px;

      @media screen and (max-width: 780px) {
        font-size: 28px;
      }
    }

    &__card3 {
      background-color: #6854ff;
      font-size: 34px;

      @media screen and (max-width: 780px) {
        font-size: 28px;
      }

      img {
        max-width: 767px;
        width: 100%;

        // margin-left: -30%;
        @media screen and (max-width: 780px) {
          margin-left: 0;
        }
      }
    }
  }

  &__section2 {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    @media screen and (max-width: 780px) {
      flex-direction: column;
    }

    &>div {
      flex: 1;
      display: flex;
      gap: 24px;
      flex-direction: column;
    }

    &__card {
      padding: 32px 24px;
      border-radius: 32px;
      min-height: 312px;
      box-sizing: border-box;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 16px;
      }

      &__text {
        font-size: 18px;
      }

      &__image {
        width: 100%;
        text-align: right;
        margin-bottom: 6px;

        img {
          margin-right: 24px;
        }
      }

      &--1 {
        background-color: #fac900;
        padding: 32px 0 0 0;

        .Home__section2__card {

          &__title,
          &__text {
            padding: 0 24px;
          }

          &__image {
            img {
              margin-right: 48px;
            }
          }
        }

        &__bottom-image {
          background-image: url("./images/card1_bot.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 32px;
          height: 318px;
          margin-top: 50px;
        }
      }

      &--2 {
        background-color: #6854ff;
      }

      &--3 {
        background-color: #ff8d54;
      }

      &--4 {
        background-color: #53c28d;
      }

      &--5 {
        background-color: #548dff;
      }
    }
  }

  &__section3 {
    &__card {
      background: white;
      border-radius: 32px;
      color: #191919;
      display: flex;

      @media screen and (max-width: 780px) {
        flex-direction: column;
      }

      &__info {
        padding: 64px;
        flex: 1;

        @media screen and (max-width: 780px) {
          padding: 24px;
        }
      }

      &__title {
        color: #6854ff;
        font-weight: 500;
        margin-bottom: 16px;
        font-size: 24px;
      }

      &__text {
        margin-bottom: 16px;
        font-size: 18px;

        a {
          color: #6854ff;
          text-decoration: none;
        }
      }

      &__cta {
        &--1 {
          button {
            background-color: #fac900;
            color: #191919;
            height: 48px;
            width: 244px;
            text-transform: none;

            &:hover {
              background-color: #fac900;
            }
          }
        }

        &--2 {
          margin-top: 20px;

          button {
            margin-top: 12px;
          }
        }
      }

      &__image {
        background-image: url("./images/contact.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        width: 411px;

        @media screen and (max-width: 780px) {
          height: 328px;
          width: 100%;
        }
      }
    }
  }
}