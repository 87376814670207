.PricingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;

  @media screen and (max-width: 860px) {
    padding: 0px;
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    margin-bottom: 24px;
    max-width: 800px;

    span {
      color: #6854ff;
    }

    @media screen and (max-width: 860px) {
      font-size: 24px;
    }
  }

  &__subtitle {
    margin-bottom: 48px;
    opacity: 0.7;
    max-width: 1000px;

    @media screen and (max-width: 860px) {
      margin-bottom: 32px;
    }
  }

  &__warranty {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #FFF6D1;
    border-radius: 24px;
    padding: 12px 24px;
    margin-bottom: 24px;

    &__description {
      span {
        font-size: 16px;
        font-weight: 600;
        color: #6854FF;
      }

      font-size: 14px;
    }
  }

  &__demo {
    margin-bottom: 40px;

    button {
      text-transform: none;
      height: 48px;
    }
  }

  &__plans {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    @media screen and (max-width: 860px) {
      flex-direction: column;
    }
  }

  &__plan {
    flex: 1;
    background: #fff;
    border-radius: 32px;
    padding: 24px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 860px) {
      padding: 8px;
    }

    &__image {
      background: linear-gradient(180deg, #FF8D54 0%, #EED2C4 100%);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      margin-bottom: 32px;

      @media screen and (max-width: 860px) {
        margin-bottom: 24px;
      }
    }

    &--pro {
      &__image {
        background: linear-gradient(180deg, #35CCAD 0%, #55C9B1 100%);
      }
    }

    &__description {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;

      @media screen and (max-width: 860px) {
        text-align: center;
      }
    }

    &__price {
      margin-bottom: 24px;

      @media screen and (max-width: 860px) {
        text-align: center;
      }

      .amount {
        font-size: 34px;
        font-weight: bold;
        color: #6854FF;
      }

      .terms {
        font-size: 20px;

        @media screen and (max-width: 860px) {
          font-weight: 500;

          &__price {
            display: block;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }

    &__includes {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 16px;
      color: #6854FF;

      @media screen and (max-width: 860px) {
        text-align: center;
      }
    }

    &__features {
      list-style: none;
      padding: 0;
      margin: 0 0 32px 0;

      @media screen and (max-width: 860px) {
        padding: 0 16px;
        font-size: 14px;
      }

      li {
        margin-bottom: 16px;
        padding-left: 24px;
        position: relative;

        &:before {
          content: "•";
          position: absolute;
          left: 0;
          color: #6854FF;
        }

        strong {
          font-weight: 600;
        }

      }
    }

    &__button {
      margin-top: auto;

      button {
        text-transform: none;
        height: 48px;
      }

      @media screen and (max-width: 860px) {
        padding: 16px;
      }
    }
  }

  &__notes {
    margin-top: 64px;
    width: 100%;

    &__title {
      display: flex;
      align-items: center;
      font-size: 20px;
      gap: 8px;
      margin-bottom: 32px;
      justify-content: space-between;

      .PricingPage__demo {
        margin-bottom: 0;
      }

      @media screen and (max-width: 860px) {
        flex-direction: column-reverse;
        gap: 16px;
        align-items: flex-start;
      }

      span:first-child {
        color: #6854FF;
        font-weight: 500;
      }

      span:last-child {
        font-weight: 500;
      }
    }

    &__grid {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      @media screen and (max-width: 860px) {
        flex-direction: column;
      }

      &__item {
        flex: 1;
        border-radius: 16px;

        &__header {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 16px;

          img {
            width: 24px;
            height: 24px;
          }

          h3 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
          }
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          margin: 0;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
}