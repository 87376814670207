.RestaurantCampaigns {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
  }
  &__text {
    margin-bottom: 32px;
  }
  &__container {
    display: flex;
    gap: 30px;
    justify-content: space-between;
  }
  &__list {
    flex: 1;
    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
      padding-right: 32px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      height: 37px;
    }
    &__name {
      font-weight: 600;
    }
  }
  &__campaign {
    height: 84px;
    border: 1px solid rgba(104, 84, 255, 0.4);
    box-shadow: 0px 2px 2px rgba(104, 84, 255, 0.1);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &--brand {
      background: rgba(104, 84, 255, 0.1);
    }
    span {
      font-weight: bold;
    }
  }
}
