.WIW {
  background: white;

  .WIW__content-wrapper {
    max-width: 1280px;
    width: 100%;
    padding: 0 80px;

    @media screen and (max-width: 950px) {
      padding: 0 24px;
    }
  }

  &__header-background {
    background: #6854ff;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 56px 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      padding: 40px 0;
    }
  }

  &__header {
    color: white;
    display: flex;
    gap: 48px;

    @media screen and (max-width: 950px) {
      flex-direction: column;
      gap: 40px;
    }

    &__content {
      flex: 1;
    }

    &__video {
      flex: 1;
      position: relative;
      height: auto;
      overflow: hidden;

      @media screen and (max-width: 950px) {
        position: initial;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 950px) {
          position: initial;
          min-height: 220px;
        }
      }
    }

    &__title {
      font-size: 34px;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 24px;
    }

    &__cta {
      button {
        background-color: #fac900;
        color: #191919;
        height: 48px;
        width: 244px;
        text-transform: none;
        margin-bottom: 12px;
        font-weight: 400;

        &:hover {
          background-color: #fac900;
        }
      }

      &__tip {
        font-size: 12px;
        font-weight: 300;
      }
    }

    &__images {
      display: flex;
      gap: 45px;

      @media screen and (max-width: 1250px) {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
      }

      &__1 {
        width: 120px;
        height: auto;
        margin-top: 77px;
      }

      &__2 {
        width: 400px;
        height: auto;
        margin-bottom: -50px;
      }
    }
  }

  &__section1 {
    display: flex;
    justify-content: center;
    padding: 80px 0;

    @media screen and (max-width: 950px) {
      padding: 48px 0;
    }

    &__title {
      font-weight: 400;
      font-size: 34px;
      margin-bottom: 50px;

      @media screen and (max-width: 950px) {
        margin-bottom: 32px;
      }

      span {
        color: #6854ff;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      gap: 48px;

      @media screen and (max-width: 950px) {
        flex-direction: column;
        gap: 32px;
      }
    }

    &__item {
      flex: 1;

      @media screen and (max-width: 950px) {
        padding-bottom: 32px;
        border-bottom: 2px solid #cccccc;
      }

      &__title {
        font-weight: 400;
        font-size: 20;
        margin-bottom: 12px;

        span {
          font-size: 34px;
          color: #53c28d;
        }
      }
    }
  }

  &__section2-background {
    background: #6854ff;
    padding: 80px 0 48px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      padding: 48px 0;
    }
  }

  &__section2 {
    width: 100%;

    &__title {
      font-weight: 400;
      font-size: 34px;
      color: white;
      margin-bottom: 50px;

      @media screen and (max-width: 950px) {
        margin-bottom: 32px;
      }

      span {
        color: #fac900;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      gap: 48px;
      margin-bottom: 48px;

      @media screen and (max-width: 950px) {
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
      }
    }

    &__item {
      flex: 1;
      max-width: 285px;
      min-height: 276px;
      background-color: #53c28d;
      color: white;
      padding: 24px;
      border-radius: 32px;
      box-sizing: border-box;

      @media screen and (max-width: 950px) {
        width: 161px;
        flex: none;
        padding: 8px;
        font-size: 14px;
        font-weight: 300;
      }

      &__text {
        @media screen and (max-width: 950px) {
          padding: 8px;
        }
      }

      &__image {
        margin-bottom: 24px;
        height: 144px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #3ba672;
        border-radius: 32px;

        @media screen and (max-width: 950px) {
          width: 144px;
          margin-bottom: 8px;
        }
      }
    }

    &__cta {
      text-align: center;

      button {
        background-color: #fac900;
        color: #191919;
        height: 48px;
        width: 244px;
        text-transform: none;
        margin-bottom: 12px;
        font-weight: 400;
        margin-bottom: 0;

        &:hover {
          background-color: #fac900;
        }
      }
    }
  }

  &__section3-background {
    padding: 64px 0 48px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      padding: 48px 0;
    }
  }

  &__section3 {
    width: 100%;

    &__title {
      font-weight: 400;
      font-size: 34px;
      margin-bottom: 40px;

      @media screen and (max-width: 950px) {
        margin-bottom: 32px;
      }

      span {
        color: #6854ff;
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      gap: 48px;
      flex-wrap: wrap;

      @media screen and (max-width: 950px) {

        gap: 24px;
        margin-bottom: 0;
      }
    }

    &__item {
      width: 285px;
      flex: 1;
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 24px 16px;
      gap: 18px;
      align-items: center;
      background: #F1F0FF;
      border-radius: 32px;
      font-size: 15px;
      font-weight: 300;
    }
  }

  &__success {
    padding: 64px 0 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      padding: 0 0 48px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 48px;

      @media screen and (max-width: 950px) {
        flex-direction: column;
      }
    }

    &__title {
      font-weight: 400;
      font-size: 34px;
      margin-bottom: 40px;

      @media screen and (max-width: 950px) {
        margin-bottom: 32px;
      }

      span {
        color: #6854ff;
      }
    }

    &__subtitle {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 16px;
    }

    li {
      margin-bottom: 16px;
      font-weight: 300;
    }

    &__content {
      flex: 1;

      button {
        background-color: #6854ff;
        color: white;
        height: 48px;
        margin-top: 32px;
        text-transform: none;
        font-weight: 400;
        width: 244px;
      }
    }

    &__image {
      flex: 1;

      &__wrapper {
        width: 100%;
        height: 100%;
        background-color: #6854ff;
        border-radius: 150px;

        @media screen and (max-width: 950px) {
          max-width: 400px;
          border-radius: 54px;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__section4 {
    padding: 80px 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 950px) {
      padding: 48px 0;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: url("./images/section4bg.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 32px;
      width: 100%;
      box-sizing: border-box;
      color: white;
      margin: 0 24px;

      @media screen and (max-width: 768px) {
        padding: 0 !important;
      }
    }

    &__content {
      max-width: 680px;

      @media screen and (max-width: 1000px) {
        max-width: none;
        padding: 80px 0 40px 0;
      }

      @media screen and (max-width: 950px) {
        padding: 200px 24px 24px;
      }
    }

    &__title {
      font-size: 34px;
      font-weight: 400;
      line-height: 120%;
      color: #ffe554;
      margin-bottom: 12px;

      @media screen and (max-width: 950px) {
        font-size: 20px;
      }

      span {
        color: white;
      }
    }

    &__subtitle {
      font-weight: 300;
      font-size: 15px;
      margin-bottom: 38px;

      @media screen and (max-width: 950px) {
        margin-bottom: 24px;
      }
    }

    &__ctas {
      display: flex;
      gap: 24px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      @media screen and (max-width: 460px) {
        button {
          width: 100%;
        }
      }

      &__1 {
        button {
          background-color: #6854ff;
          color: white;
          height: 48px;
          text-transform: none;
          margin-bottom: 12px;
          font-weight: 400;

          &:hover {
            background-color: #6854ff;
          }
        }
      }

      &__2 {
        button {
          background-color: #fac900;
          color: #191919;
          height: 48px;
          text-transform: none;
          margin-bottom: 12px;
          font-weight: 400;

          &:hover {
            background-color: #fac900;
          }
        }
      }
    }

    &__image {
      @media screen and (max-width: 1000px) {
        display: none;
      }

      max-width: 264px;

      img {
        height: height;
        width: 100%;
      }
    }
  }

  &__faqs {
    display: flex;
    justify-content: center;
    margin-top: 64px;

    &__wrapper {
      width: 100%;
      max-width: 838px;
      padding: 0 24px;
    }

    &__title {
      font-weight: 400;
      font-size: 34px;
      margin-bottom: 50px;
      color: #6854ff;

      @media screen and (max-width: 950px) {
        margin-bottom: 32px;
      }
    }
  }

  &__footer {
    padding: 80px 0;
    display: flex;
    justify-content: center;
    color: white;

    @media screen and (max-width: 950px) {
      padding: 48px 0;
    }

    &__wrapper {
      background: #6854ff;
      width: 100%;
      border-radius: 32px;
      padding: 80px !important;
      position: relative;
      box-sizing: border-box;
      margin: 0 24px;

      @media screen and (max-width: 950px) {
        padding: 24px !important;
      }
    }

    &__image {
      position: absolute;
      top: 32px;
      right: 32px;

      @media screen and (max-width: 950px) {
        display: none;
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 48px;

      @media screen and (max-width: 950px) {
        margin-bottom: 40px;
      }
    }

    button {
      background-color: #fac900;
      color: #191919;
      height: 48px;
      text-transform: none;
      margin-bottom: 12px;
      font-weight: 400;
      width: 244px;

      @media screen and (max-width: 460px) {
        width: 100%;
      }

      &:hover {
        background-color: #fac900;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      gap: 48px;
      font-size: 20px;
      line-height: 120%;

      @media screen and (max-width: 950px) {
        flex-direction: column;
        gap: 40px;
      }

      span {
        color: #fac900;
      }
    }

    &__item {
      flex: 1;
    }

    &__text {
      margin-bottom: 32px;
    }
  }

  &__ctas {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 12px;
    position: sticky;
    bottom: 12px;
    width: 512px;
    left: 0;
    right: 0;
    margin: auto;

    @media screen and (max-width: 768px) {
      width: auto;
      margin: 0 24px;
    }

    &__1 {
      @media screen and (max-width: 768px) {
        flex: 1;
      }

      button {
        background-color: #6854ff;
        color: white;
        height: 48px;
        width: 309px;
        text-transform: none;
        margin-bottom: 12px;
        font-weight: 400;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:hover {
          background-color: #6854ff;
        }
      }
    }

    &__2 {
      @media screen and (max-width: 768px) {
        flex: 1;
      }

      button {
        background-color: #fac900;
        color: #191919;
        height: 48px;
        width: 180px;
        text-transform: none;
        margin-bottom: 12px;
        font-weight: 400;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:hover {
          background-color: #fac900;
        }
      }
    }
  }
}